import { ProjectUUID } from '../projects/projectsTypes';
import { SearchFilterNanoID } from '../searchFilters/searchFiltersTypes';
import { TaskID, TaskNanoID, TaskUserID } from './tasksTypes';
import { UserNanoID } from '../users/usersTypes';
import { TeamNanoID } from '../teams/teamsTypes';

export class TaskCache {
  static indexCacheKey() {
    return 'tasks';
  }

  static filteredCacheKey(searchFilterNanoId: SearchFilterNanoID) {
    return `tasks-filtered-${searchFilterNanoId}`;
  }

  static burningCacheKey() {
    return 'tasks-burning';
  }

  static withoutSourceFiles() {
    return 'tasks-without-source-files';
  }

  static dashboardWithoutSourceFiles() {
    return 'tasks-dashboard-without-source-files';
  }

  static current() {
    return 'tasks-current';
  }

  static totalCountCacheKey() {
    return 'tasks-total-count';
  }

  static burningTotalCountCacheKey() {
    return 'tasks-burning-total-count';
  }

  static myTotalCountCacheKey() {
    return 'tasks-my-total-count';
  }

  static favoritesTotalCountCacheKey() {
    return 'tasks-favorites-total-count';
  }

  static resultsTotalCountCacheKey() {
    return 'tasks-results-total-count';
  }

  static favoriteCacheKey() {
    return 'tasks-favorite';
  }

  static myTasksCacheKey() {
    return 'my-tasks';
  }

  static companyTasksCacheKey(companyNanoId: TeamNanoID) {
    return `tasks-company-${companyNanoId}`;
  }

  static companyTasksFavoriteCacheKey(companyNanoId: TeamNanoID) {
    return `tasks-favorite-company-${companyNanoId}`;
  }

  static companyTasksInProgressCacheKey(companyNanoId: TeamNanoID) {
    return `tasks-in-progress-company-${companyNanoId}`;
  }

  static companyTasksBurningCacheKey(companyNanoId: TeamNanoID) {
    return `tasks-burning-company-${companyNanoId}`;
  }

  static companyTasksResultsCacheKey(companyNanoId: TeamNanoID) {
    return `tasks-results-company-${companyNanoId}`;
  }

  static userTasksCacheKey(userNanoId: UserNanoID) {
    return `tasks-user-${userNanoId}`;
  }

  static userActiveTasksCacheKey() {
    return 'user-active-tasks';
  }

  static userProfileTasksCacheKey(userNanoId: UserNanoID) {
    return `tasks-user-${userNanoId}-profile`;
  }

  static itemsResultsCacheKey() {
    return 'tasks-results';
  }

  static filteredResultsCacheKey(searchFilterNanoId: SearchFilterNanoID) {
    return `tasks-filtered-results-${searchFilterNanoId}`;
  }

  static showCacheKey() {
    return 'task';
  }

  static showRedirectCacheKey(taskId: TaskID) {
    return `project-${taskId}-redirect`;
  }

  static showBreadcrumbsCacheKey() {
    return 'task-breadcrumbs';
  }

  static userResultsCacheKey() {
    return 'tasks-user-results';
  }

  static userUnderpaymentCacheKey(userNanoId: UserNanoID) {
    return `tasks-user-${userNanoId}-underpayment`;
  }

  static userDebtCacheKey(userNanoId: UserNanoID) {
    return `tasks-user-${userNanoId}-debt`;
  }

  static userBadDebtCacheKey(userNanoId: UserNanoID) {
    return `tasks-user-${userNanoId}-bad-debt`;
  }

  static userBadDebtAmountCacheKey(userNanoId: UserNanoID) {
    return `tasks-user-${userNanoId}-bad-debt-amount`;
  }

  static selectByProjectFieldCacheKey(projectUuid: ProjectUUID) {
    return `select-by-project-${projectUuid}-field-tasks`;
  }

  static activeTaskCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-active-task`;
  }

  static messagesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-messages`;
  }

  static pinnedMessagesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-pinned-messages`;
  }

  static favoriteMessagesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-favorite-messages`;
  }

  static scopeOfWorkCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-scope-of-work`;
  }

  static forwardMessageCacheKey(projectUuid: ProjectUUID) {
    return `tasks-${projectUuid}-forward-messages`;
  }

  static taskMembersCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-task-members`;
  }

  static taskNotificationsCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-task-notifications`;
  }

  static sidebarFilesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-sidebar-files`;
  }

  static sidebarMediaCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-sidebar-media`;
  }

  static filesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-files`;
  }

  static fileAttachmentsCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-file-attachments`;
  }

  static imagesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-images`;
  }

  static resultsCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-results`;
  }

  static resultsCountCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-results-count`;
  }

  static sourceFilesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-source-files`;
  }

  static selectedProductsCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-selected-products`;
  }

  static selectedLifestylesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-selected-lifestyles`;
  }

  static selectedMaterialsCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-selected-materials`;
  }

  static transactionsCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-transactions`;
  }

  static billsCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-bills`;
  }

  static showQueryKey(taskNanoId: TaskNanoID) {
    return ['task', taskNanoId];
  }

  static transactionsCountCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-transactions-count`;
  }

  static userTasksCountCacheKey(userId: TaskUserID) {
    return `user-${userId}-tasks-count`;
  }

  static userResultsCountCacheKey(userId: TaskUserID) {
    return `user-${userId}-results-count`;
  }

  static importTasksToProject() {
    return 'import-tasks-to-project';
  }

  static taskMembersInvitesCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-members-invites`;
  }

  static taskMembersInvitesCountCacheKey(taskNanoId: TaskNanoID) {
    return `task-${taskNanoId}-members-invites-count`;
  }

  static companyUserTasksCacheKey(
    companyNanoId: TeamNanoID,
    userNanoId: UserNanoID
  ) {
    return `tasks-company-${companyNanoId}-user-${userNanoId}`;
  }

  static companyUserTasksResultsCacheKey(
    companyNanoId: TeamNanoID,
    userNanoId: UserNanoID
  ) {
    return `tasks-results-company-${companyNanoId}-user-${userNanoId}`;
  }

  static companyUserTasksBurningCacheKey(
    companyNanoId: TeamNanoID,
    userNanoId: UserNanoID
  ) {
    return `tasks-burning-company-${companyNanoId}-user-${userNanoId}`;
  }

  static companyUserTasksFavoriteCacheKey(
    companyNanoId: TeamNanoID,
    userNanoId: UserNanoID
  ) {
    return `tasks-favorite-company-${companyNanoId}-user-${userNanoId}`;
  }

  static companyUserProjectsCacheKey(
    companyNanoId: TeamNanoID,
    userNanoId: UserNanoID
  ) {
    return `projects-company-${companyNanoId}-user-${userNanoId}`;
  }
}
