import { SearchFilterNanoID } from '../searchFilters/searchFiltersTypes';
import { UserNanoID, UserUUID } from '../users/usersTypes';
import { TeamNanoID } from '../teams/teamsTypes';

export class MessageCache {
  static indexCacheKey() {
    return 'messages';
  }

  static myMessagesCacheKey() {
    return 'my-messages';
  }

  static totalsCacheKey() {
    return 'messages-totals';
  }

  static unreadCacheKey() {
    return 'unread-messages';
  }

  static visibleForClientCacheKey() {
    return 'visible-for-client-messages';
  }

  static onlyClientsCacheKey() {
    return 'only-clients-messages';
  }

  static userMessagesCacheKey(userNanoId: UserNanoID) {
    return `messages-user-${userNanoId}`;
  }

  static filteredCacheKey(searchFilterNanoId: SearchFilterNanoID) {
    return `messages-filtered-${searchFilterNanoId}`;
  }

  static showCacheKey() {
    return 'message';
  }

  static unreadTotalCountCacheKey() {
    return 'messages-unread-total-count';
  }

  static onlyClientsTotalCountCacheKey() {
    return 'messages-only-clients-total-count';
  }

  static userTotalCountCacheKey(userUuid: UserUUID) {
    return `user-${userUuid}-messages-total-count`;
  }

  static companyMessagesCacheKey(companyNanoId: TeamNanoID) {
    return `messages-company-${companyNanoId}`;
  }

  static companyMessagesNewCacheKey(companyNanoId: TeamNanoID) {
    return `messages-new-company-${companyNanoId}`;
  }
}
